<template>
  <b-modal
      id="modal-reload-page"
      ref="modal-reload-page"
      footer-class="flex-nowrap"
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.reloadPage.title') }}</h3>
    </template>

    <template #default>
      <div class="delete-icon text-center">
        <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
        <div class="mt-2 mb-4 mx-4">{{ $t('modals.reloadPage.message') }}</div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('cancel-reload-page')"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          variant="primary"
          class="p-3"
          block
          @click="reloadPage"
      >
        {{ $t('modals.reloadPage.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalReloadPage',
  mounted() {
    this.$refs['modal-reload-page'].show();
  },
  methods: {
    reloadPage() {
      this.$emit('reload-page');
      this.$refs['modal-reload-page'].hide();
    },
    cancelReloadPage() {
      this.$emit('cancel-reload-page');
      this.$refs['modal-reload-page'].hide();
    },
  },
};
</script>

<style scoped>
.delete-icon svg {
  font-size: 80px;
}
</style>
